<template>
  <div>
    <v-card>
      <v-card-title>
        Unidades
        <v-spacer> </v-spacer>
        <v-btn text color="success" @click="openDialoag()"
          >Adicionar Unidade</v-btn
        >
      </v-card-title>
    </v-card>
    <!-- TABELA -->
    <v-data-table
      :headers="header"
      :items="getUnidades"
      item-key="name"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Filtro"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="openDialoag(item)">mdi-pencil</v-icon>
        <v-icon class="mx-2" small @click="delUnidade(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <!-- DIALOG UNIDADE-->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >{{ unidade.id ? "Editar" : "Adicionar" }} Unidade
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  label="Nome da Unidade"
                  v-model="unidade.name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  :items="this.$store.getters.getGroupSelect"
                  label="Grupo"
                  v-model="unidade.groupId"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Ip Primario"
                  v-model="unidade.ip1"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Ip Secundario"
                  v-model="unidade.ip2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialoag">
            Cancelar
          </v-btn>
          <v-btn color="success" text @click="saveUnidade">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Unidades",
  components: {},
  data: () => {
    return {
      search: "",
      dialog: false,
      unidade: {
        name: "",
        groupId: 0,
        ip1:"",
        ip2:"",
      },
      header: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "IP Primario", value: "ip1" },
        { text: "IP Secundario", value: "ip2" },
        { text: "Cadastrado", value: "createdAt" },
        { text: "Modificado", value: "updatedAt" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  methods: {
    get() {
      this.$store.dispatch("loadUnidades");
      this.$store.dispatch("loadGrupos");
    },
    closeDialoag() {
      this.unidade = {};
      this.dialog = false;
    },
    openDialoag(unidade) {
      //   this.unidade = {};
      if (unidade) {
        this.unidade = unidade;
      }
      this.dialog = true;
    },
    saveUnidade() {
      this.$store.dispatch("saveUnidade", this.unidade);
      this.closeDialoag();
    },
    delUnidade(item){
        this.$store.dispatch("deletUnidade", item);
    }
  },
  computed: {
    getUnidades: {
      get() {
        return this.$store.getters.getUnidades;
      },
      set() {},
    },
  },
  mounted() {
    this.get();
  },
};
</script>

<style>
</style>